// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Schools for good | Learning Wings Education System',
  nakedPageSlug: 'schools-for-good',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Schools for good">
        Schools for good
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          All Learning Wings Schools are deeply committed to community service.
          The schools are a center of dedicated action for the good of people.
          We work together with many great organisations to improve the life
          conditions of people. We also have many unique programs of which our
          school students and teachers are an integral part. Projects we are
          proud to be a part of –
        </p>
        <h2 className="mask-h4">Walk of Hope 2015-16</h2>
        <p>
          Walk of Hope was a padayatra for peace and harmony by the Manav Ekta
          Mission which covered 7500 kms from Kanyakumari to Kashmir. Led by Sri
          M, founder of Manav Ekta Mission, the journey spanned 16 months
          through 11 Indian states, bringing together citizens from all walks of
          life. Traversing the varied landscape of India, it sought a
          significantly restored nation—fit enough to be bequeathed to our
          future generations.
        </p>
        <p>
          The Walk of Hope was flagged off on 12 January 2015, the birth
          anniversary of a great prophet of interfaith harmony, Swami
          Vivekananda. Starting from the Gandhi Memorial Mandapam in
          Kanyakumari, it concluded in Srinagar, Jammu & Kashmir on 29 April
          2016, reaching out to 10 million fellow Indians during the Walk.
        </p>
        <p>
          Learning Wings was involved intimately with this initiative, and it's
          team persons were in the national co-ordinating team.
        </p>
        <h2 className="mask-h4">Satsang Vidyalaya</h2>
        <p>
          Satsang Vidyalaya is an educational initiative for under-privileged
          children. Begun in 1999, Satsang Vidyalaya comprises of two schools
          and over 200 students. The schools offer completely free education to
          children from the age of 6 up to the ages of 15. In addition to free
          schooling, Satsang Vidyalaya also provides a free mid-day lunch for
          all students.
        </p>
        <p>
          Satsang Vidyalaya follows the state school syllabus as prescribed by
          the Andhra Pradesh board. The school has 15 teachers on its rolls
          across subjects such as Science, Mathematics, Social sciences,
          English, and Languages. There is a strong focus on infrastructure with
          multiple classrooms being built, advanced learning techniques, and
          teacher training with the intention of providing students free yet
          un-compromised education to help them progress in life. Learning Wings
          supports and works with Satsang Vidyalaya to make quality education
          for the under-privileged a possibility.
        </p>
        <h2 className="mask-h4">MyTree</h2>
        <p>
          MyTree is inspired by the Sanskrit word – Maitri – meaning amity.
          Amity conforms to the concept of Manav Ekta (oneness of humanity)
          propounded by Sri M, which is being implemented through the various
          programs of the Mission. It is envisioned that by encouraging an
          individual/community-led partnership to plant trees, a natural human
          alliance will be formed to ensure a sustainable relationship with
          nature. This amity or Mytree (derived from Maitri) is the crux of the
          initiative.
        </p>
        <p>
          Learning Wings' students regularly undertake MyTree drive in their
          respective localities, and have, till day planted over 50,000
          saplings.
        </p>
        <h2 className="mask-h4">Deaf & Dumb School</h2>
        <p>
          Deaf & Dumb School is an initiative to provide quality education to
          impaired individuals with the goal of making them self-sufficient,
          independent and productive individuals of society.
        </p>
        <p>
          Learning Wings students provide companionship and compassion to the
          learners with their disability with regular visits and build a bond to
          support in all possible areas.
        </p>
        <h2 className="mask-h4">Digital Tibet</h2>
        <p>
          Digital Tibet is an initiative by the Tibetan Community and other
          volunteers to preserve records of the Tibetan people. It's first
          project, "Tibet in Exile", is a photo journal about the life of
          Tibetans after they exiled. The photo journal will help the younger
          generation of Tibetans understand and appreciate the history and
          origins of the Exiled Tibetan Community, and illustrate to them - as
          well as non Tibetans around the world—the extraordinary achievements
          of the early refugee community.
        </p>
        <p>
          This project is funded by National Geographic, and Dalai Lama has
          written the foreword of the book. Learning Wings has supported this
          initiative by providing technical assistance.
        </p>
        <h2 className="mask-h4">Goonj</h2>
        <p>
          Goonj is a non-governmental organisation based in Delhi, India which
          undertakes disaster relief, humanitarian aid and community development
          in parts of 21 states across India. It recycles discarded clothes and
          household goods into useful products for the poor, such as sanitary
          napkins. It collects and delivers 1,000 tons of material every year
          through a network of 500 volunteers and 250 partners. It also runs
          infrastructure and local development projects in villages and slum
          areas.
        </p>
        <p>
          Learning Wings is a partner and students regularly participate in
          activities with the goal of procuring relief material for the
          under-privileged.
        </p>
        <h2 className="mask-h4">The Hudli Project</h2>
        <p>
          The Hudli Project is an initiative designed to create employment in
          Hudli village. It is supported by the Khadigram of Hudli Village.
          Poverty, deprivation and unemployment are resulting in people leaving
          Hudli and moving to towns in search of a better livelihood. The
          immediate goal of The Hudli Project is to create guaranteed employment
          for at least 100 women for an entire year (or longer) by creating
          demand for the pickles made by the Khadigram.
        </p>
        <p>
          Learning Wings supports The Hudli Project by purchasing merchandise
          created by women of the village.
        </p>
      </div>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
