// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import omit from 'lodash/omit'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

import { Timeline } from 'react-twitter-widgets'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../Link'
import StandardPage from './StandardPage'

// import '../../styles/about.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Functions
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** K12SchoolPageWrapper */
const K12SchoolPageWrapper = props => {
  const { className, seoData, children } = props

  return (
    <StandardPage
      className={className}
      seoData={seoData}
      {...omit(props, ['children'])}
    >
      <div className="small-default-container">
        <Division threeQuarters>
          <div style={{ maxWidth: '45rem' }}>
            <Fragment>{children}</Fragment>
          </div>
          <div className="details">
            <div>
              <h2 className="mask-h4">K12 Schools</h2>
              <ul className="mask-p">
                <li>
                  <Link to="/k12-schools">About</Link>
                </li>
                <li>
                  <Link to="/k12-school-locations">Locations</Link>
                </li>
                <li>
                  <Link to="/why-lwes-school">Why us?</Link>
                </li>
                <li>
                  <Link to="/core-values">Core values</Link>
                </li>
                <li>
                  <Link to="/lwes-curriculum">Our curriculum</Link>
                </li>
                <li>
                  <Link to="/schools-for-good">Schools for good</Link>
                </li>
                <li>
                  <Link to="/k12-schools-timeline">Our timeline</Link>
                </li>
              </ul>
              <h2 className="mask-h4">Schools on Twitter...</h2>
              <div
                style={{
                  background: '#fafafa',
                  boxShadow: '6px 6px 0px #f4c220',
                }}
              >
                <Timeline
                  dataSource={{
                    sourceType: 'profile',
                    screenName: 'cambridge_jal',
                  }}
                  options={{
                    username: 'cambridge_jal',
                    height: '800',
                  }}
                />
              </div>
            </div>
          </div>
        </Division>
      </div>
    </StandardPage>
  )
}

K12SchoolPageWrapper.propTypes = {}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default K12SchoolPageWrapper
